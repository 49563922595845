@import url('https://fonts.googleapis.com/css2?family=Chonburi&family=Montserrat:wght@400;600&family=Playfair:wght@600&family=Raleway:wght@500&family=Source+Serif+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Patua+One&family=Rubik+Puddles&display=swap');

body{
    overflow-x: hidden !important;
}

.news-letter-main{
    border-radius: 5px;
    border-color: rgba(212, 212, 212, 0.24) !important;
}

.news-letter{
    background: none !important;
    border: none;
    color: white;
}


.news-letter:focus{
    box-shadow: 0 0 0 0 !important;
    color: white;
}

.footer{
    background-color: #060033;
}

.font-family{
    font-family: 'Chonburi', cursive;

}

.back-shadow{
    content: '';
    position: absolute;
    /* width: 1px; */
    /* height: 1px; */
    box-shadow: 0 0 200px 45px  #7979d3 ;
    /* background-color: red; */
    border-radius: 50%;
}

.single-font{
    font-family: 'Aboreto', cursive;
}

.text-primary{
    color: #7979d3 !important;
}

.text-org{
    color: #FF6B00;
}

.text-dark-2{
    color: rgb(68, 68, 68) !important;
}

.rounded-5{
    border-radius: 15px;
}

.nav-dark{
    background-color: rgb(255, 255, 255)(0, 255, 115)(0, 153, 255)(0, 26, 255)(0, 195, 255)(54, 54, 54) !important;
    backdrop-filter: blur(10px);
}

.nav-dark ul li a{
    color: black !important;
}

.nav-dark ul li a{
    color: black !important;
}


.text-primary-2{
    color: #312783 !important;
}

.navbar{
    width: 100% !important;
}

.nav-link{
    font-family: 'Roboto', sans-serif;
}

.btn-primary{
    background-color: #312783 !important;
    border: none !important;
    transform: 0.2s;
    box-shadow: 0px 2px 6px 0px #32278380;
}

.btn-primary:focus{
    box-shadow: 0 0 0 0 !important;
}

.btn-primary:hover{
    transform: scale(1.02);
    box-shadow: 0px 2px 6px 1px #32278380;
}

.btn-outline-primary{
    border: 1px solid #312783 ;
    color: #312783 ;
}

.btn-outline-primary:hover{
    background-color: #312783 !important;
    color: white;
    border-color: #312783;
    transform: scale(1.02);
    box-shadow: 0px 2px 6px 1px #32278380;
}

.btn-outline-primary:focus{
    box-shadow: 0 0 0 0 !important;
}

.bg-purple{
    background-color: #32278310;
    border: 1px solid rgba(128, 128, 128, 0.185);
}

/* main banner css */


.animated-text {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  }
  
  .slide-in {
    opacity: 1;
    transform: translateY(0);
  }
  

.product-card{
    border-radius: 15px !important;
    min-height: 200px;
    background-color: #ffffffe3 !important;
}


.line-1{
    transform: rotate(130deg);
}

.indecator{
    height: 15px !important;
    width: 15px !important;
    border-radius: 50%;
}

.carousel-control-prev{
    width: 80px;
    height: 80px;
}

.carousel-indicators{
    right: 10px !important;
}

.carousel-control-next{
    width: 80px;
    height: 80px;
}

.carousel-control-prev{
    height: 80px;
    width: 80px;
}

.carousel-control-next{
    height: 80px;
    width: 80px;
}


.hero-heading{
    font-family: 'Righteous', cursive;
    font-size: 55px;
}

#hero-main-sec{
    margin-top: 40px;
}

.hero-btn{
    border-radius: 25px;
    background: linear-gradient( #7979d3, #120a50d5);
}

.hero-btn-2{
    border-radius: 25px;
}

.hero-btn-2:hover{
    background: linear-gradient( #7979d3, #120a50d5);
}

.text-style-vec{
    position: relative;
    right: 100px;
    top: 80px;
    transform: rotate(130deg);
}



.count-sec{
    border-radius: 15px !important;
}




.next{
    position: relative;
    transition: 0.2s all ease;
    cursor: pointer;
}

.next:hover {
    transform: translateX(10px);
}




.carousel-control-prev{
    height: 50px;
    width: 50px;
    border-radius: 50%;
}

.carousel-control-next{
    height: 50px;
    width: 50px;
    border-radius: 50%;
}


/* teams meet card css */
.meet-team-card{
    background: url(../img/card-shadow.png) no-repeat top;
    transition: 0.5s all;
}

.meet-team-card:hover{
    transform: translatey(-20px);
}

.tran-y{
    transform: translatey(-15px);
}



#team{
    margin-top: 120px !important;
}

#plain-section{
    margin-top: 120px !important;
    background: #dedefa42;
}


.plan-card{
    border: 1px solid rgb(224, 224, 224);
    border-radius: 8px;
    background-color: white;
}


.bg-dark-shade{
    background-color: #060033;
}

#our-services{
    margin-top: 250px !important;
    background: url(/src/assets/img/06.jpg) no-repeat center ;
    background-size: cover;
    height: 900px;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 59%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 59%, 0 100%);
}

#service-overlay{
    height: 100%;
    background:  rgba(1, 2, 36, 0.74);
    width: 100%;
}


.main-servicwe-section{
    border-radius: 15px;
}

.font-family-2{
    font-family: 'Patua One', cursive;
    font-weight: 400 !important;
}

.margin-top{
    margin-top: 70px !important;
}

.service-card{
    position: relative;
    border-radius: 10px;
    animation: serviceCard 1.9s infinite alternate;
    background: url(../img/card-shadow.png) no-repeat;
}

.icon{
    border-radius: 8px;
    background: linear-gradient( #7979d3, #120a50d5);
    color: white;
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.24);
}
  
@keyframes serviceCard {
    0% {
      top: 0;
    }
    100% {
      top: 25px;
    }
  }

.num-count h1{
    font-size: 5em ;
    color: rgba(3, 3, 3, 0.062);
    z-index: 100;
    /* font-family: 'Rubik Puddles', cursive; */
}

.card-shadow{
    border-radius: 44% 56% 17% 83% / 30% 60% 40% 70% ;
    background-color: #f502020c;
    height: 180px;
    width: 380px;
    position: absolute;
    top: 100px;
    left: 30px;
    z-index: -1;
}

.arrow-container {
    position: relative;
    height: 100px;
    width: 100px;
    animation: arrow-animation 1.5s infinite alternate;
  }
  
  @keyframes arrow-animation {
    0% {
      top: 0;
    }
    100% {
      top: 25px;
    }
  }

  
  .down-aero-hero {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    font-size: 85px;
    font-weight: 100;
  }
  


  .forum-head{
    transform: rotate(-46deg);
    left: 5%;
    top: 30px;
  }

  #community-img{
    border-radius: 25px;
  }



  /* clients cards css */

.client-img{
    /* width: 180px !important; */
    aspect-ratio: 3/2 !important;
    object-fit: contain !important;
    /* mix-blend-mode: color-burn; */
}

.client-card{
    /* background-color: rgb(241, 239, 234); */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s all ease-in-out;
}

.client-card:hover{
    transform: scale(1.10);
}

#clients{
    margin-top: 120px;
    margin-bottom: 150px ;
}

.community-desc-img{
    width: 130%;
}



/* contact page css */
.form-control:focus{
    box-shadow: none !important;
    border: 1px solid rgba(0, 20, 134, 0.13);
}

.form-select:focus{
    box-shadow: 0 0 0 3px rgba(0, 20, 134, 0.13) !important;
    border: 1px solid rgba(0, 20, 134, 0.089);
}


/* About Page css */

.choose-card{
    min-height: 350px;
}

.choose-card-icon{
    background-color: #4747f11a;
    color: #5b5bf8e5;
    font-size: 30px !important;
}





/* services Page Css */

#service-page-header{
    background-color: #060033;
    height: 600px;
}

.play-button{
    background-color: #060033;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.233);
    cursor: pointer;
}

.play-button i{
    font-size: 45px !important;
    color: white;
}



@media screen and (max-width: 961px) {
    
    .login-signup-img{
        display: none !important;
    }
    .community-desc-img{
        width: 100% !important;
    }
    .navbar{
        position: relative !important;
    }

    .nav-link{
        color: black !important;

    }

    #light-logo{
        display: none !important;
    }

    #logo-dark{
        display: block !important;
    }
    .ceo{
        width: 100% !important;
    }
    .team-members{
        width: 100% !important;
    }
    .blogs-banner{
        width: 100% !important;
    }

    #service-sub-sec{
        margin-top: 420px !important;
    }

    .service-banner-img{
        width: 100% !important;
    }
    .banner-shadow{
        display: none;
    }
}



@media screen and (max-width: 750px) {
    .login-signup-logo{
        top: 2px !important;
    }
    .header-heading{
        font-size: 35px !important;
    }
    .product-card{
        display: none !important;
    }

    .padding-0{
        padding: 0 !important;
    }

    #service-sub-sec{
        margin-top: 320px !important;
    }
    .contact-aero{
        display: none !important;
    }
    .home-titles{
        width: 100% !important;
    }
}


@media screen and (max-width: 482px) {
    .header-heading{
        font-size: 25px !important;
    }

    .carousel-control-prev{
        width: 30px !important;
        height: 30px !important;
    }

    .carousel-control-next{
        width: 30px !important;
        height: 30px !important;
    }
    .num-count{
        display: none !important;
    }
    #service-text-section{
        padding-top: 30px !important;
    }

    #service-sub-sec{
        margin-top: 220px !important;
    }
}


@media screen and (max-width: 400px) {
    #service-sub-sec{
        margin-top: 120px !important;
    }
}


@media screen and (max-width: 360px) {
    #service-sub-sec{
        margin-top: 80px !important;
    }
}